<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h5>
          <i class="fa fa-server"></i>
          {{ lang('sections') }}
          &nbsp;
          <button class="btn btn-primary btn-sm" v-b-modal.add style="border-radius: 50%; width: 30px; height: 30px; padding: 0px">
            <i class="fa fa-plus"></i>
          </button>
        </h5>
      </div>
      <div class="card-footer">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-sm">
            <thead>
              <th>
                {{ lang("title") }}
              </th>
              <th>
                {{ lang("devices") }}
              </th>
            </thead>
            <tbody>
              <tr v-for="section in sections" :key="section._id">
                <td>
                  {{ section.title }}
                </td>
                <td>
                  <span style="font-size: 16px">{{ section.devices }}</span> <a href="javascript:;" @click="$router.push('/devices?section_id=' + section._id)"><i class="fa fa-eye"></i> {{ lang('view') }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <b-modal id="add" :title="lang('add')" hide-footer>
      <div class="form-group">
        <h5 for="">{{ lang('title') }}</h5>
        <input type="text"
          class="form-control" :placeholder="lang('write-here')" v-model="create.title">
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-primary" @click="addnow()">
          {{ lang('add') }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal,
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
      return {
        create: {title: ''},
        user: JSON.parse(localStorage.getItem('user')),
        sections: []
      }
    },
    beforeDestroy(){
    },
    created(){
      var g = this;
      this.getsections()
    },
    methods: {
      getsections(){
        var g = this;
        $.post(api + '/user/devices/sections', {
          jwt: g.user.jwt,
        }).then(function(r){
          g.sections = r.response
        })
      },
      addnow(){
        var g = this;
        $.post(api + '/user/devices/sections/add', {
          jwt: g.user.jwt,
          title: g.create.title,
          lang: localStorage.getItem("lang")
        }).then(function(r){
          if(r.status == 100){
            g.getsections();
            $("#add___BV_modal_header_ > button").click()
          }else{
            alert(r.response, 200)
          }
        }).fail(function(){
          alert(lang("error"))
        })
      }
    }
}
</script>

<style>

</style>
